@use "base";

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  background-color: base.$light;
}

.sec-pad {
  padding: 8rem 0;
}

.heading {
  text-transform: uppercase;
}

.heading-lt {
  color: base.$light;
}

.heading-sec {
  display: block;
  margin-bottom: 5rem;
  text-align: center;
  color: base.$dark;

  .heading-sec__main {
    font-size: 2.5rem;
  }

  .heading-sec__sub {
    font-size: 1.2rem;
    padding: 0 1.5rem;
    line-height: 1.7;
  }
}

.text-grey {
  color: grey;
}

.btn {
  padding: 1rem 3rem;
  width: 10rem;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}

.btn-primary {
  border: none;
  background-color: base.$primary;
  color: base.$light;
}

.btn-primary:hover {
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  color: base.$dark;
  border: base.$primary solid 2px;
}

.btn-secondary {
  border: base.$primary solid 2px;
  background-color: transparent;
  color: base.$dark;
}

.btn-secondary:hover {
  background-color: base.$primary;
  transition: all 0.2s ease-in-out;
  color: base.$light;
  border: none;
}


/* === PROJECTS === */
.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: #fafafa;
}

.projects-container {
  max-width: 1400px;
}

.github-cta {
  text-decoration: underline;
}

/* PROJECT CARD */
.project-card {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  justify-items: center;
  margin-bottom: 15rem;
  padding: 0 2rem;
  height: 500px;
}

.project-card__img {
  width: 100%;
}

.project-card__content {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 1.7rem;
  }

  p {
    margin-bottom: 2rem;
    line-height: 1.7;
  }

  .project-card__btn-cont {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
}


/* === ABOUT ME === */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: base.$light;
}

.about-cont {

  max-width: 1400px;
}

.about__content {
  .about__content-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
  }
  
  .about__content-title{
    font-size: 1.7rem
  }
  
  .about__content-para {
    margin-bottom: 2rem;
    line-height: 1.7;
    font-size: 1.2rem;
  }
}

.content-main__contact-btn {
  text-transform: uppercase;
  text-align: center;
}

.about__content-skills {
  padding: 2rem;

  .skills {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      p {
        color: grey;
        font-size: 1.1rem;
      }
    }
  }
}


/* === CONTACT === */
.contact {
  background: url(./../assets/blue-bg.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  strong {
    color: base.$secondary;
  }
}


/* === FOOTER === */
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  width: 100vw;
  background-color: base.$dark;
}

.footer__content {
  width: 100%;
  padding: 2rem 1rem;
}

.footer__social-cont {
  margin-bottom: 3rem;

  .footer__icon {
    width: 25px;
    margin-right: 1rem;
  }
}
