$primary: #064663;
$secondary: #ECB365;
$tertiary: #04293A;
$light: #EEEEEE;
$dark: #041C32;

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}



body {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;

}